// components
import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/campro-sports/campro-sports-header-image.jpg";
import Dollar from "../images/confi/dollar.svg";
import Sizeicon from "../images/limton/size-green.svg";
import ApparelG from "../images/seyfeli/Apparel.svg";
import CheckIcon from "../images/icon/check.svg";
import Facts from "../images/confi/location.svg";
import Size from "../images/confi/size.svg";
import Profile from "../images/confi/profile.svg";
import Foursource from "../images/confi/foursource.svg";
import Apparel from "../images/confi/apparel.svg";
import ApparelManufacturer from "../images/confi/apparelManufacturer.svg";
import Banner from "../images/confi/banner.svg";
import ansar from "../images/campro-sports/ansar-bashir-campro-sports.jpg";


export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("campro-sports.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("campro-sports.subTitle"),
        colorClass: "colorLight",
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("campro-sports.imgAlt"),
      },
    },
    topIcons: [
      {
        title: {
          text: t("campro-sports.newOrdersValue"),
        },
        subtitle: {
          text: t("campro-sports.newOrdersSubtitle"),
          colorClass: "colorDark text-center",
        },
        icon: {
          src: ApparelG,
          alt: t("campro-sports.newOrdersAlt"),
        },
      },
      {
        title: {
          text: t("campro-sports.newCustomersValue"),
        },
        subtitle: {
          text: t("campro-sports.newCustomersSubtitle"),
          colorClass: "colorDark text-center",
        },
        icon: {
          src: Sizeicon,
          alt: t("campro-sports.newCustomersAlt"),
        },
      },
      {
        title: {
          text: t("campro-sports.savedValue"),
        },
        subtitle: {
          text: t("campro-sports.savedSubtitle"),
          colorClass: "colorDark text-center",
        },
        icon: {
          src: Dollar,
          alt: t("campro-sports.savedAlt"),
        },
      }
    ],
    sideIcons1: [
      {
        title: {
          text: t("campro-sports.locationTitle"),
        },
        subtitle: {
          text: t("campro-sports.locationSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Facts,
          alt: t("campro-sports.locationAlt"),
        },
      },
      {
        title: {
          text: t("campro-sports.productTitle"),
        },
        subtitle: {
          text: t("campro-sports.productSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Apparel,
          alt: t("campro-sports.productAlt"),
        },
      },
      {
        title: {
          text: t("campro-sports.memberTitle"),
        },
        subtitle: {
          text: t("campro-sports.memberSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: ApparelManufacturer,
          alt: t("campro-sports.memberAlt"),
        },
      },
      {
        title: {
          text: t("campro-sports.membershipTitle"),
        },
        subtitle: {
          text: t("campro-sports.membershipSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Profile,
          alt: t("campro-sports.membershipAlt"),
        },
      },
      {
        title: {
          text: t("campro-sports.sizeTitle"),
        },
        subtitle: {
          text: t("campro-sports.sizeSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Size,
          alt: t("campro-sports.sizeAlt"),
        },
      },
      {
        title: {
          text: t("campro-sports.sinceTitle"),
        },
        subtitle: {
          text: t("campro-sports.sinceSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Foursource,
          alt: t("campro-sports.sinceAlt"),
        },
      },
    ],
    sideIcons2: [
      {
        title: {
          text: t("campro-sports.challengesSquareTitle"),
          textSize: "font24 lineHeight1_3 ",
        },
        subtitle: {
          text: t("campro-sports.challengesSquareSubtitle"),
          colorClass: "colorDark",
          textSize: "font17  pt-10"
        },
        icon: {
          src: '',
          alt: t("campro-sports.newMarketsAlt"),
        },
      },
    ],
    sideIcons3: [
      {
        title: {
          text: t("campro-sports.favoriteToolsTitle"),
          textSize: "font24 lineHeight1_3 ",
        },
        subtitle: {
          text: t("campro-sports.favoriteToolsSubtitle"),
          colorClass: "colorDark",
          textSize: " font17 pt-20"
        },
        icon: {
          src: '',
          alt: t("campro-sports.favoriteToolsAlt"),
        },
        banner: true,
        bannerSrc: Banner,
        bannerAlt: "Banner"
      },
    ],
    ansar: [
      {
        title: {
          text: t("campro-sports.ansarName"),
        },
        subtitle: {
          text: t("campro-sports.ansarTitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: ansar,
          alt: t("campro-sports.newMarketsAlt"),
        },
      },
    ],
    aboutInfo: {
      title: {
        text: t("campro-sports.aboutTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("campro-sports.aboutText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    challengesInfo: {
      title: {
        text: t("campro-sports.challengesTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("campro-sports.challengesText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    findingInfo: {
      title: {
        text: t("campro-sports.findingTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("campro-sports.findingText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    resultsInfo: {
      title: {
        text: t("campro-sports.resultsTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      separator: true,
    },
    bullet: {
      icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt") },
      separator: false,
      item: {
        text: [
          t("campro-sports.bullet1"),
          t("campro-sports.bullet2"),
          t("campro-sports.bullet3"),
          t("campro-sports.bullet4"),
          t("campro-sports.bullet5"),
        ],
        colorClass: "colorDark",
      },
      customPBText: true,
    },
    bottomText: [
    ],
    cta: {
      text: t("campro-sports.cta1"),
      url: "https://foursource.com/apparel-manufacturers/",
      type: "whiteColor",
    },
    cta2: {
      text: t("campro-sports.cta2"),
      url: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      type: "mainColor",
    },
    squareText: t("campro-sports.challengesSquareSubtitle2"),
    favoriteToolsExtra: t("campro-sports.favoriteToolsExtra"),
    profileText: t("campro-sports.profileText"),
    testimonyName: t("campro-sports.ansarName"),
    testimonyTitle: t("campro-sports.ansarTitle"),
    anchorText: t("campro-sports.anchorText")
  };
};
